export const theme = {
  colors: {
    black: "#000000",
    white: "#FFF",
    blue: "#4765DD",
    red: "#FF1085",
    linearGradient:
      "linear-gradient(90deg, #4765DD 0%, #FF1085 50.25%, #FF6B15 100%)",
  },
};
