import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { styled, keyframes } from '@stitches/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { AxiosError } from 'axios';
import { InputZenet } from '../input';
import DateNow from '../date';
import ZenetSelect from '../select';
import ZenetCheckBoxRadioComponent from '../checkbox-radio';
import MultiRangeSlider from '../double-range';
import { Line } from '../sidebar/components/line';
import { Button } from '../button';
import { useSnackBarAlert } from '../alert';

import { post_groups, Gender, Filter, CreateGroupSchema } from '../../services/groups/create';

import { defaultValues } from './const';
import MoneyInput from '../money-input';

const feedback = {
  name: 'Um grupo com o mesmo nome já existe.',
};

interface DialogContextProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}
export const DialogContext = createContext<DialogContextProps>({} as DialogContextProps);

export const DialogProviderCreateGroup = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false);
  const onOpenChange = (open: boolean) => setOpen(open);

  return <DialogContext.Provider value={{ open, onOpenChange }}>{children}</DialogContext.Provider>;
};

export const useDialogCreateGroup = (): DialogContextProps => useContext(DialogContext);

interface Props {
  open: boolean;
  onClose: () => void;
}

const ModalAddUserToGroup = ({ open, onClose }: Props) => {
  const [submitting, setSubmitting] = useState(false);

  const [groupName, setGroupName] = useState(defaultValues.groupName);
  const [mediumTicket, setMediumTicket] = useState(defaultValues.mediumTicket);
  const [age, setAge] = useState(defaultValues.age);
  const [zenetRating, setZenetRating] = useState(defaultValues.zenetRating);
  const [state, setState] = useState(defaultValues.state);
  const [gender, setGender] = useState<Gender>(defaultValues.gender as Gender);

  const { setOpenSnackBarAlert, setTypeSnackBarAlert, setMessage } = useSnackBarAlert();

  const handleSubmit = async () => {
    const canSubmit = groupName && state;

    if (!canSubmit) return;

    const filter = {
      age_min: age.min,
      age_max: age.max,
      zenet_rating_min: zenetRating.min,
      zenet_rating_max: zenetRating.max,
      average_entry_transaction_min: mediumTicket.min,
      average_entry_transaction_max: mediumTicket.max,
      state,
      country: 'Brasil',
    } as Filter;

    if (gender !== 'ALL') filter.gender = gender;

    const data: CreateGroupSchema = {
      name: groupName,
      filter,
    };

    try {
      setSubmitting(true);

      await post_groups(data);

      onClose();

      setMessage('Grupo criado com sucesso!');
      setTypeSnackBarAlert('success');
      setOpenSnackBarAlert(true);
    } catch (e) {
      const error = e as AxiosError<Record<string, string[]>>;

      let message: string;

      if (!error.response) {
        message = 'Erro ao criar grupo, tente novamente mais tarde.';
      } else {
        const errorKeyWithLabel = Object.keys(error.response!.data).find((key) => {
          return key in feedback;
        }) as keyof typeof feedback;

        message = feedback[errorKeyWithLabel];
      }

      setMessage(message);
      setTypeSnackBarAlert('error');
      setOpenSnackBarAlert(true);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setGroupName(defaultValues.groupName);
      setMediumTicket(defaultValues.mediumTicket);
      setAge(defaultValues.age);
      setZenetRating(defaultValues.zenetRating);
      setState(defaultValues.state);
      setGender(defaultValues.gender as Gender);
    }
  }, [open]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent>
          <DialogContentContainer>
            <InputZenet
              type="text"
              placeholder="Definir nome do grupo"
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
            />
            <DateNow />
            <Line width="100%" marginTop="15px" />
            <ZenetSelect handleChange={(value: string) => setState(value)} value={state} />
            <ZenetCheckBoxRadioComponent selectedGender={gender} onChange={(value: Gender) => setGender(value)} />
            <RangeContainer>
              <ZenetLegend>Faixa etária</ZenetLegend>
              <DialogInputContainer>
                <DialogInput50 value={`${age.min} Anos`} type="text" placeholder="18 Anos" disabled />
                <span> - </span>
                <DialogInput50 value={`${age.max} Anos`} type="text" placeholder="80 Anos" disabled />
              </DialogInputContainer>

              <MultiRangeSlider
                min={18}
                max={80}
                onChange={({ min, max }) => {
                  setAge({ min, max });
                }}
              />
            </RangeContainer>
            <RangeContainer style={{ marginTop: 52 }}>
              <ZenetLegend>Ticket Médio</ZenetLegend>
              <DialogInputContainer style={{ marginBottom: 0 }}>
                <DialogInput50Currency
                  currentValue={mediumTicket.min}
                  onChangeInput={(value) => {
                    setMediumTicket((prev) => ({ ...prev, min: value }));
                  }}
                  minValue={0}
                  maxValue={mediumTicket.max}
                />
                <span> - </span>
                <DialogInput50Currency
                  currentValue={mediumTicket.max}
                  onChangeInput={(value) => {
                    setMediumTicket((prev) => ({ ...prev, max: value }));
                  }}
                  minValue={mediumTicket.min}
                  maxValue={1000000}
                />
              </DialogInputContainer>
            </RangeContainer>
            <RangeContainer>
              <ZenetLegend>Zenet Rating</ZenetLegend>
              <DialogInputContainer>
                <DialogInput50 value={zenetRating.min} type="text" placeholder="1" disabled />
                <span> - </span>
                <DialogInput50 value={zenetRating.max} type="text" placeholder="5" disabled />
              </DialogInputContainer>

              <MultiRangeSlider
                min={1}
                max={5}
                onChange={({ min, max }) => {
                  setZenetRating({ min, max });
                }}
              />
            </RangeContainer>
            <Button label="Criar grupo" onClick={handleSubmit} disabled={submitting} />
          </DialogContentContainer>
          <Dialog.Close asChild onClick={onClose} disabled={submitting}>
            <IconButton aria-label="Close">
              <Cross2Icon height={24} width={24} />
            </IconButton>
          </Dialog.Close>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const DialogContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
});

const DialogOverlay = styled(Dialog.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const DialogContent = styled(Dialog.Content, {
  backgroundColor: 'white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '95vh',
  overflow: 'auto',
  padding: 50,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },

  borderRadius: '16px',
  borderRight: '1px solid #DDD',
  borderBottom: '1px solid #DDD',
  borderLeft: '1px solid #DDD',
  background: '#FFF',
});

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgba(0, 0, 0, 1)',
  position: 'absolute',
  top: 10,
  right: 10,

  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
  '&:focus': { boxShadow: 'white' },
  cursor: 'pointer',
});

export const DialogInput = styled('input', {
  width: '100%',
  height: '48px',
  flexShrink: '0',
  borderRadius: '4px',
  background: '#eee',
  border: 'none',
  padding: '10px',
  fontSize: '16px',
  marginBottom: '17px',

  '&::placeholder': {
    color: '#000',
    fontSize: '16px!important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

export const DialogInput50 = styled('input', {
  width: '45%',
  height: '48px',
  flexShrink: '0',
  borderRadius: '4px',
  background: '#eee',
  border: 'none',
  padding: '10px',
  fontSize: '16px',

  '&::placeholder': {
    color: '#000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },

  '&:disabled': {
    color: '#000',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    cursor: 'not-allowed',
  },
});

export const DialogInput50Currency = styled(MoneyInput, {
  width: '45%',
  height: '48px',
  flexShrink: '0',
  borderRadius: '4px',
  background: '#eee',
  border: 'none',
  padding: '10px',
  fontSize: '16px',

  '&::placeholder': {
    color: '#000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

const DialogInputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '17px',
});

const RangeContainer = styled('div', {
  marginTop: '40px',
});

const ZenetLegend = styled('legend', {
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  marginBottom: '10px',
});

export default ModalAddUserToGroup;
export type { Gender };
