import React from "react";
import { IconProps } from "./types";

export const Person: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_254_948)">
        <path
          d="M16 17.3337C19.1947 17.3337 22.1 18.259 24.2373 19.5617C25.304 20.215 26.216 20.9817 26.8747 21.815C27.5227 22.6363 28 23.6177 28 24.667C28 25.7937 27.452 26.6817 26.6627 27.315C25.916 27.915 24.9307 28.3123 23.884 28.5897C21.78 29.1457 18.972 29.3337 16 29.3337C13.028 29.3337 10.22 29.147 8.116 28.5897C7.06933 28.3123 6.084 27.915 5.33733 27.315C4.54667 26.6803 4 25.7937 4 24.667C4 23.6177 4.47733 22.6363 5.12533 21.815C5.784 20.9817 6.69467 20.215 7.76267 19.5617C9.9 18.259 12.8067 17.3337 16 17.3337ZM16 2.66699C17.7681 2.66699 19.4638 3.36937 20.714 4.61961C21.9643 5.86986 22.6667 7.56555 22.6667 9.33366C22.6667 11.1018 21.9643 12.7975 20.714 14.0477C19.4638 15.2979 17.7681 16.0003 16 16.0003C14.2319 16.0003 12.5362 15.2979 11.286 14.0477C10.0357 12.7975 9.33333 11.1018 9.33333 9.33366C9.33333 7.56555 10.0357 5.86986 11.286 4.61961C12.5362 3.36937 14.2319 2.66699 16 2.66699Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_254_948">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
