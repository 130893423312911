import styled from 'styled-components';
import { Gender } from '../modal-create-group';

const Container = styled.div`
  margin-top: 40px;
`;

export const ZenetCheckBoxRadio = styled.input`
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  stroke-width: 1px;
  stroke: #ddd;
`;

const ZenetCheckBoxRadioContainer = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const ZenetLegend = styled.legend`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
`;

export const ZenetCheckBoxRadioLabel = styled.label`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2px;
  margin-left: 6px;
`;

const ZenetOptionContainer = styled.div`
  margin-right: 5px;
  display: flex;
`;

interface Props {
  onChange: (name: Gender) => void;
  selectedGender: Gender;
}

const ZenetCheckBoxRadioComponent = ({ onChange, selectedGender }: Props) => {
  return (
    <Container>
      <ZenetLegend>Gênero</ZenetLegend>
      <ZenetCheckBoxRadioContainer>
        <ZenetOptionContainer>
          <ZenetCheckBoxRadio
            type="radio"
            id="ALL"
            name="ALL"
            checked={selectedGender === 'ALL'}
            onChange={(e) => onChange(e.target.name as Gender)}
          />
          <ZenetCheckBoxRadioLabel htmlFor="all">Todos</ZenetCheckBoxRadioLabel>
        </ZenetOptionContainer>
        <ZenetOptionContainer>
          <ZenetCheckBoxRadio
            type="radio"
            id="M"
            name="M"
            checked={selectedGender === 'M'}
            onChange={(e) => onChange(e.target.name as Gender)}
          />
          <ZenetCheckBoxRadioLabel htmlFor="M">Masculino</ZenetCheckBoxRadioLabel>
        </ZenetOptionContainer>
        <ZenetOptionContainer>
          <ZenetCheckBoxRadio
            type="radio"
            id="F"
            name="F"
            checked={selectedGender === 'F'}
            onChange={(e) => onChange(e.target.name as Gender)}
          />
          <ZenetCheckBoxRadioLabel htmlFor="F">Feminino</ZenetCheckBoxRadioLabel>
        </ZenetOptionContainer>
        <ZenetOptionContainer>
          <ZenetCheckBoxRadio
            type="radio"
            id="U"
            name="U"
            checked={selectedGender === 'U'}
            onChange={(e) => onChange(e.target.name as Gender)}
          />
          <ZenetCheckBoxRadioLabel htmlFor="U">Outros</ZenetCheckBoxRadioLabel>
        </ZenetOptionContainer>
      </ZenetCheckBoxRadioContainer>
    </Container>
  );
};

export default ZenetCheckBoxRadioComponent;
