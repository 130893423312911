import React, { Fragment, useEffect, useState } from 'react';
import { Menu, MenuItem, SubMenu, MenuItemStyles } from 'react-pro-sidebar';

import { SidebarHeader } from './components/sidebar-header';
import { Velocity } from './components/icons/velocity';
import { Logout } from './components/icons/logout';
import { Person } from './components/icons/person';
import { Group } from './components/icons/group';
import { Line } from './components/line';
import styled from 'styled-components';
import { Link, Outlet } from 'react-router-dom';
import Breadcrumb from '../breadcrumb';
import { Sidebar } from './styles';
import { useAuth } from '../../contexts/AuthContext';
import { ExportIcon } from '../table/icons/export';

const Root = styled.div`
  display: flex;
  height: 100%;
  direction: ltr;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  direction: ltr;
`;

const SidebarHeaderContainer = styled.div`
  margin-bottom: 50px;
  margin-top: 30px;
`;

const SubMenuContainer = styled.div`
  margin-top: 50px;
`;

const OutletContainer = styled.div`
  background: #f5f3f6;
  width: 100%;
  padding-top: 70px;
  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const LogoutMenu = styled(Menu)`
  margin: auto 0 24px;
`;

const SidebarComponent = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [activeMenu, setActiveMenu] = useState('');

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontFfamily: 'DM Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    icon: {
      color: 'white',
      pointerEvents: 'none',
    },
    subMenuContent: ({ level, open, active }) => ({
      backgroundColor: 'transparent',
      marginLeft: '25px',
      boxShadow: 'none',
      pointerEvents: open ? 'auto' : 'none',
      cursor: 'pointer',
    }),
    button: {
      '&:hover': {
        opacity: 0.9,
        backgroundColor: 'transparent',
      },
    },
    label: ({ open, active }) => ({
      color: collapsed ? 'transparent' : 'white',
      cursor: 'pointer',
      fontWeight: active || open ? 600 : 500,
    }),
  };

  const rootStyles = {
    color: 'white',
    width: '222px',
    height: '100vh',
    minWidth: '222px',
  };

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const activeMenu = path[path.length - 1];
    setActiveMenu(activeMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const { logout } = useAuth();

  return (
    <Root data-testid="sidebar-component">
      <Sidebar
        collapsed={collapsed}
        toggled={true}
        collapsedWidth="100px"
        backgroundColor={'transparent'}
        rootStyles={rootStyles}
        data-testid="sidebar"
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
      >
        <SidebarContainer>
          <SidebarHeaderContainer>
            <SidebarHeader />
          </SidebarHeaderContainer>
          <Line />
          <Menu
            menuItemStyles={menuItemStyles}
            renderExpandIcon={() => <Fragment />}
            closeOnClick
          >
            <SubMenuContainer>
              <SubMenu label="Dashboards" icon={<Velocity size={32} />}>
                <MenuItem
                  component={<Link to="/dashboard/retention" />}
                  active={activeMenu === 'retention'}
                >
                  Retenção
                </MenuItem>
                <MenuItem
                  component={<Link to="/dashboard/demographic" />}
                  active={activeMenu === 'demographic'}
                >
                  Demográfico
                </MenuItem>
                <MenuItem
                  component={<Link to="/dashboard/churn" />}
                  active={activeMenu === 'churn'}
                >
                  Churn
                </MenuItem>
                <MenuItem
                  component={<Link to="/dashboard/benchmark" />}
                  active={activeMenu === 'benchmark'}
                >
                  Benchmark
                </MenuItem>
                <MenuItem
                  component={<Link to="/dashboard/behavior" />}
                  active={activeMenu === 'behavior'}
                >
                  Comportamental
                </MenuItem>
              </SubMenu>
            </SubMenuContainer>
            <SubMenuContainer>
              <SubMenu label="Apostadores" icon={<Person size={32} />}>
                <MenuItem
                  component={<Link to="/players/general" />}
                  active={activeMenu === 'general'}
                >
                  Geral
                </MenuItem>
                <MenuItem
                  component={<Link to="/players/vips" />}
                  active={activeMenu === 'vips'}
                >
                  VIPs
                </MenuItem>
                <MenuItem
                  component={<Link to="/players/actives" />}
                  active={activeMenu === 'actives'}
                >
                  Ativos
                </MenuItem>
                <MenuItem
                  component={<Link to="/players/abandoned-transactions" />}
                  active={activeMenu === 'abandoned-transactions'}
                >
                  Transações Abandonadas
                </MenuItem>
              </SubMenu>
            </SubMenuContainer>
            <SubMenuContainer>
              <SubMenu
                active={activeMenu === 'groups'}
                label="Grupos (coortes)"
                icon={<Group size={32} />}
                href="/groups"
              />
            </SubMenuContainer>
            <SubMenuContainer>
              <SubMenu
                active={activeMenu === 'reports'}
                label="Exportações"
                icon={<ExportIcon size={32} />}
                href="/reports"
              />
            </SubMenuContainer>
          </Menu>
          <LogoutMenu
            menuItemStyles={menuItemStyles}
            renderExpandIcon={() => <Fragment />}
          >
            <SubMenuContainer>
              <SubMenu
                label="Sair"
                icon={<Logout size={32} />}
                onClick={logout}
              />
            </SubMenuContainer>
          </LogoutMenu>
        </SidebarContainer>
      </Sidebar>
      <OutletContainer>
        <Breadcrumb />
        <Line width="100%" marginTop="70px" marginBottom="50px" />
        <Outlet />
      </OutletContainer>
    </Root>
  );
};

export default SidebarComponent;
