/* istanbul ignore next */
import { useRoutes } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Router from "./index";

const PrivateRoute = (): React.ReactElement<
  unknown,
  string | React.JSXElementConstructor<unknown>
> | null => {
  const { token } = useAuth();
  const logged = localStorage.getItem("token_zenet") ? true : false;
  const routing = useRoutes(Router({ logged, token }));
  return routing;
};

export default PrivateRoute;
