import styled from "styled-components";

export const Line = styled.hr<{
  width?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  width: ${(props) => props.width || "222px"};
  margin-top: ${(props) => props.marginTop || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  color: white;
  opacity: 0.5;
`;
