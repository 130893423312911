import React, {
  useState,
  useContext,
  createContext,
  forwardRef,
  useMemo,
  useCallback,
} from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Spin } from "../table/icons/spin";

interface SnackBarAlertContextProps {
  open: boolean;
  setOpenSnackBarAlert: (open: boolean) => void;
  type: SnackBarAlertType;
  setTypeSnackBarAlert: (type: SnackBarAlertType) => void;
  message: string;
  setMessage: (message: string) => void;
}

const SnackBarAlertContext = createContext<SnackBarAlertContextProps>(
  {} as SnackBarAlertContextProps
);

export const SnackBarAlertProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpenSnackBarAlert] = useState(false);
  const [type, setTypeSnackBarAlert] = useState<SnackBarAlertType>("success");
  const [message, setMessage] = useState("");

  return (
    <SnackBarAlertContext.Provider
      value={{
        open,
        setOpenSnackBarAlert,
        type,
        setTypeSnackBarAlert,
        message,
        setMessage,
      }}
    >
      {children}
    </SnackBarAlertContext.Provider>
  );
};

export const useSnackBarAlert = (): SnackBarAlertContextProps =>
  useContext(SnackBarAlertContext);

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type SnackBarAlertType = "success" | "error" | "warning" | "info";

const SnackBarAlert = () => {
  const { open, setOpenSnackBarAlert, type, message } = useSnackBarAlert();

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setOpenSnackBarAlert(false);
    },
    [setOpenSnackBarAlert]
  );

  const Snack = useMemo(() => {
    switch (type) {
      case "error": {
        return (
          <Alert
            severity="error"
            style={{ backgroundColor: "rgba(255, 16, 16, 1)" }}
            iconMapping={{
              error: <ReportGmailerrorredIcon fontSize="inherit" />,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSnackBarAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        );
      }
      case "success": {
        return (
          <Alert
            severity="success"
            style={{ backgroundColor: "rgba(12, 220, 83, 1)" }}
            iconMapping={{
              success: <CheckCircleIcon fontSize="inherit" />,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSnackBarAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        );
      }
      case "info": {
        return (
          <Alert
            severity="info"
            style={{ backgroundColor: "rgba(71, 101, 221, 1)" }}
            iconMapping={{
              info: (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ),
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSnackBarAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        );
      }
    }
  }, [message, setOpenSnackBarAlert, type]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={type === 'info' ? undefined : 4000}
      >
        {Snack}
      </Snackbar>
    </Stack>
  );
};

export default SnackBarAlert;
