import axios, { AxiosInstance } from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const token_api: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/authentication/token/`,
});

export const refresh_token_api: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/authentication/token/refresh/`,
});

export const password_reset = axios.create({
  baseURL: `${BASE_URL}/authentication/password-reset/`,
});

export const confirm_password_reset = axios.create({
  baseURL: `${BASE_URL}/authentication/password-reset/confirm/`,
});

export const base_api: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
});

const tokenInterceptor = (config: any) => {
  const token = localStorage.getItem("token_zenet");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

base_api.interceptors.request.use(tokenInterceptor);

base_api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = "/";
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);
