import { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRouter from './router/PrivateRoute';
import GlobalStyle from './styles/global-style';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Loading } from './components/loading';
import { DialogProviderCreateGroup } from './components/modal-create-group';
import { DialogProviderAddUserToGroup } from './components/modal-add-user-to-group';
import SnackBarAlert, { SnackBarAlertProvider } from './components/alert';

import 'react-day-picker/dist/style.css';
import 'leaflet/dist/leaflet.css';

const queryClient = new QueryClient();

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <SnackBarAlertProvider>
                <DialogProviderCreateGroup>
                  <DialogProviderAddUserToGroup>
                    <SnackBarAlert />
                    <PrivateRouter />
                  </DialogProviderAddUserToGroup>
                </DialogProviderCreateGroup>
              </SnackBarAlertProvider>
            </AuthProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
