import React from "react";
import { IconProps } from "./types";

export const Velocity: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g opacity="0.75" clipPath="url(#clip0_254_944)">
        <path
          d="M16 2C23.732 2 30 8.268 30 16C30 19.872 28.4267 23.38 25.8867 25.912C25.6975 26.1008 25.4727 26.25 25.2253 26.351C24.978 26.4519 24.7129 26.5027 24.4458 26.5002C24.1786 26.4977 23.9145 26.4421 23.6691 26.3365C23.4236 26.2309 23.2016 26.0776 23.016 25.8853L21.8933 24.7213C21.5608 24.3768 21.3629 23.9244 21.3357 23.4463C21.3084 22.9683 21.4535 22.4963 21.7447 22.1162C22.0359 21.7361 22.4538 21.4731 22.9225 21.3749C23.3911 21.2768 23.8794 21.35 24.2987 21.5813C25.3102 20.0765 25.8935 18.3249 25.9863 16.5141C26.079 14.7032 25.6776 12.9012 24.8251 11.3009C23.9725 9.70061 22.7009 8.36223 21.1463 7.42897C19.5917 6.49571 17.8126 6.00271 15.9993 6.00271C14.1861 6.00271 12.407 6.49571 10.8524 7.42897C9.29779 8.36223 8.02617 9.70061 7.17363 11.3009C6.32109 12.9012 5.91971 14.7032 6.01242 16.5141C6.10514 18.3249 6.68848 20.0765 7.70001 21.5813C8.11928 21.3491 8.60801 21.2751 9.07722 21.3729C9.54644 21.4707 9.96492 21.7338 10.2565 22.1142C10.5481 22.4946 10.6933 22.9671 10.6658 23.4456C10.6383 23.9241 10.4399 24.3768 10.1067 24.7213L8.98401 25.8853C8.79842 26.0776 8.57642 26.2309 8.33095 26.3365C8.08548 26.4421 7.82146 26.4977 7.55426 26.5002C7.28707 26.5027 7.02206 26.4519 6.77467 26.351C6.52728 26.25 6.30247 26.1008 6.11334 25.912C4.80786 24.6128 3.77232 23.0681 3.06637 21.367C2.36042 19.6658 1.998 17.8418 2.00001 16C2.00001 8.268 8.26801 2 16 2ZM16 7.33333C16.5304 7.33333 17.0391 7.54405 17.4142 7.91912C17.7893 8.29419 18 8.8029 18 9.33333V15.2013C18.7642 15.6407 19.3619 16.3202 19.7 17.1343C20.0381 17.9483 20.0977 18.8513 19.8696 19.7028C19.6415 20.5542 19.1384 21.3065 18.4386 21.8425C17.7388 22.3785 16.8815 22.6682 16 22.6667C15.1191 22.6671 14.2626 22.3768 13.5636 21.8407C12.8646 21.3046 12.3621 20.5527 12.1341 19.7018C11.9062 18.8509 11.9655 17.9485 12.3029 17.1347C12.6402 16.321 13.2368 15.6413 14 15.2013V9.33333C14 8.8029 14.2107 8.29419 14.5858 7.91912C14.9609 7.54405 15.4696 7.33333 16 7.33333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_254_944">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
