import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { styled, keyframes } from "@stitches/react";
import { Cross2Icon } from "@radix-ui/react-icons";

import CurrencyInput from "../currency-input";
import { Line } from "../sidebar/components/line";
import { ZenetCheckBoxRadio, ZenetCheckBoxRadioLabel } from "../checkbox-radio";
import { Button } from "../button";
import { useSnackBarAlert } from "../alert";
import { useDialogCreateGroup } from "../modal-create-group";
import { get_groups, add_single_user_to_group } from "../../services/groups";

interface DialogContextProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  userName: string;
  userId: string;
  setOpenData: (userName: string, id: string) => void;
}
export const DialogContext = createContext<DialogContextProps>(
  {} as DialogContextProps
);

export const DialogProviderAddUserToGroup = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const onOpenChange = (open: boolean) => setOpen(open);

  const setOpenData = (userName: string, id: string) => {
    setOpen(true);
    setUserName(userName);
    setUserId(id);
  };

  return (
    <DialogContext.Provider
      value={{ open, onOpenChange, setOpenData, userName, userId }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogAddUserToGroup = (): DialogContextProps =>
  useContext(DialogContext);

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  userName: string;
  userId: string | string[];
}

const SelectGroupContainer = styled("div", {
  display: "block",
  marginTop: "30px",
});

const CheckBoxContainer = styled("div", {
  display: "flex",
  marginBottom: "20px",
});

const CheckBox = styled(ZenetCheckBoxRadio, {
  width: "20px",
  height: "20px",
  flexShrink: 0,
});

const Label = styled(ZenetCheckBoxRadioLabel, {
  color: "#000",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const ButtonsContainer = styled("div", {
  display: "inline-flex",
  justifyContent: "space-between",
});

const ModalAddUserToGroup = ({ open, onClose, userName, userId, onSuccess }: Props) => {
  const { setOpenSnackBarAlert, setTypeSnackBarAlert, setMessage } = useSnackBarAlert();
  const { onOpenChange: openDialogCreateGroup } = useDialogCreateGroup();
  const [groups, setGroups] = useState<any[]>([]);

  const getGroups = useCallback(async () => {
    const groups = await get_groups(100, 0);
    if (groups.length > 0) {
      groups.forEach((element: any) => {
        element.checked = false;
      });
    }
    setGroups(groups.results);
  }, []);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const addUserToGroup = useCallback(() => {
    if (groups.length === 0) return;
    const group = groups.find((group) => group.checked === true);
    if (!group) return;
    if (group && group.id) {
      const userIds = Array.isArray(userId) ? userId : [userId];
      add_single_user_to_group(userIds, group.id)
        .then(() => {
          setMessage("Usuário atribuido ao grupo com sucesso");
          setTypeSnackBarAlert("success");
          setOpenSnackBarAlert(true);

          onSuccess?.();
        })
        .catch(() => {
          setMessage("Erro ao atribuir usuário ao grupo");

          setTypeSnackBarAlert("error");
          setOpenSnackBarAlert(true);
        })
        .finally(() => onClose());
    }
  }, [groups, onClose, onSuccess, setMessage, setOpenSnackBarAlert, setTypeSnackBarAlert, userId]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent>
          <DialogTitle>Atribuir grupo</DialogTitle>
          <DialogDescription>{userName}</DialogDescription>
          <Line width="100%" />
          <SelectGroupContainer>
            {groups?.map((group: any, index) => {
              return (
                <CheckBoxContainer>
                  <CheckBox
                    type="radio"
                    id={group.id}
                    name={group.name}
                    checked={group.checked}
                    onChange={() => {
                      groups.forEach((element: any) => {
                        element.checked = false;
                      });
                      groups[index].checked = true;
                      setGroups([...groups]);
                    }}
                  />
                  <Label htmlFor={group.id}>{group.name}</Label>
                </CheckBoxContainer>
              );
            })}
          </SelectGroupContainer>
          <ButtonsContainer>
            <Button
              label="Criar grupo"
              onClick={() => openDialogCreateGroup(true)}
              color="secondary"
              style={{ marginRight: "5px", width: "80%" }}
            />
            <Button
              label="Atribuir grupo"
              disabled={
                groups.length === 0 || groups.every((group) => !group.checked)
              }
              onClick={() => {
                addUserToGroup();
              }}
              style={{ width: "80%" }}
            />
          </ButtonsContainer>

          <Dialog.Close asChild onClick={onClose}>
            <IconButton aria-label="Close">
              <Cross2Icon height={24} width={24} />
            </IconButton>
          </Dialog.Close>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const DialogTitle = styled(Dialog.Title, {
  margin: 0,
  color: "rgba(71, 101, 221, 1)",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  marginBottom: 16,
});

const DialogDescription = styled(Dialog.Description, {
  margin: "10px 0 20px",

  color: "#000",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "capitalize",
});

const DialogOverlay = styled(Dialog.Overlay, {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "fixed",
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const DialogContent = styled(Dialog.Content, {
  backgroundColor: "white",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  maxHeight: "95vh",
  padding: 50,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  "&:focus": { outline: "none" },

  borderRadius: "16px",
  borderRight: "1px solid #DDD",
  borderBottom: "1px solid #DDD",
  borderLeft: "1px solid #DDD",
  background: "#FFF",
  overflow: "auto",
});

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "rgba(0, 0, 0, 1)",
  position: "absolute",
  top: 10,
  right: 10,

  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
  "&:focus": { boxShadow: "white" },
  cursor: "pointer",
});

export const DialogInput = styled("input", {
  width: "100%",
  height: "48px",
  flexShrink: "0",
  borderRadius: "4px",
  background: "#eee",
  border: "none",
  padding: "10px",
  fontSize: "16px",
  marginBottom: "17px",

  "&::placeholder": {
    color: "#000",
    fontSize: "16px!important",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
});

export const DialogInput50 = styled("input", {
  width: "45%",
  height: "48px",
  flexShrink: "0",
  borderRadius: "4px",
  background: "#eee",
  border: "none",
  padding: "10px",
  fontSize: "16px",

  "&::placeholder": {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
});
export const DialogInput50Currency = styled(CurrencyInput, {
  width: "45%",
  height: "48px",
  flexShrink: "0",
  borderRadius: "4px",
  background: "#eee",
  border: "none",
  padding: "10px",
  fontSize: "16px",

  "&::placeholder": {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
});

const DialogInputContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "17px",
});

const RangeContainer = styled("div", {
  marginTop: "40px",
});

const ZenetLegend = styled("legend", {
  color: "#000",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: "normal",
  marginBottom: "10px",
});

export default ModalAddUserToGroup;
