import React from "react";
import styled from "styled-components";

export const Spin = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} fill="none">
      <path stroke="#fff" d="M4.388 12.616A6 6 0 1 1 12.5 7" />
    </Svg>
  );
};

const Svg = styled.svg`
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
