import { tooltips } from '../const/tooltips';
import { Tooltip as TooltipBase, TooltipContent, TooltipOptions, TooltipTrigger } from '../../tooltip';
import { TooltipIcon } from '../icons/tooltip';

interface Props extends TooltipOptions {
  id: keyof typeof tooltips;
}

export const DataTooltip: React.FunctionComponent<Props> = ({ id, placement = 'bottom', ...options }) => {
  return (
    <TooltipBase {...options} placement={placement}>
      <TooltipTrigger>
        <TooltipIcon />
      </TooltipTrigger>
      <TooltipContent className="tooltip">{tooltips[id]}</TooltipContent>
    </TooltipBase>
  );
};
