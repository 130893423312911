import { Fragment } from 'react';

export const tooltips = {
  zenet_rating: (
    <Fragment>
      Zenet Rating: É uma média aritmética dos escores anteriores. Isso proporciona uma visão geral do desempenho do
      usuário em termos de frequência, valor das apostas e atividade contínua. Quanto mais alto for o Zenet Rating,
      melhor será a sua classificação geral na plataforma.
      <br />
      <br />
      Zenet Rating de Frequência: É uma avaliação atribuída ao usuário com base em sua frequência de apostas na
      plataforma usando ZenetPay como método de pagamento. Para calcular esse escore, utilizamos a curva ABC, calculada
      de forma decrescente. Quanto maior a frequência com que você faz apostas, maior será o seu escore. Isso significa
      que jogadores frequentes recebem uma pontuação mais alta.
      <br />
      <br />
      Zenet Rating Monetário: É calculado utilizando a curva ABC de forma decrescente. Ele reflete o valor total das
      suas apostas na plataforma utilizando a ZenetPay como método de pagamento. Quanto mais o jogador apostar, maior
      será o seu escore. Esse escore reconhece e fornece pontuações melhores àqueles que investem mais na plataforma.
      <br />
      <br />
      Zenet Rating de Recessão: É calculado com base na curva ABC de forma crescente. Esse escore indica quantos dias o
      jogador fica sem fazer apostas na plataforma utilizando a ZenetPay como método de pagamento. Em outras palavras,
      quanto mais tempo o usuário passar sem apostar, menor será o seu escore. Este escore incentiva a atividade
      contínua na plataforma.
    </Fragment>
  ),
  entry_transactions_count: (
    <Fragment>
      Quantidade de depósitos feitos pelo usuário na plataforma utilizando a ZenetPay como método de pagamento desde o
      seu cadastro na plataforma.
    </Fragment>
  ),
  average_entry_transaction: (
    <Fragment>
      Valor total investido pelo usuário na plataforma desde de seu cadastro dividido pela quantidade de transações.
    </Fragment>
  ),
  total_deposited: (
    <Fragment>
      Valor depositado pelo usuário na plataforma utilizando a ZenetPay como forma de pagamento nos últimos 30 dias.
    </Fragment>
  ),
  total_withdrawn: (
    <Fragment>
      É a diferença entre o valor depositado e o sacado em operações realizadas na ZenetPay nos últimos 30 dias
    </Fragment>
  ),
  zenet_profit: (
    <Fragment>
      É a diferença entre o valor depositado e o sacado em operações realizadas na ZenetPay nos últimos 30 dias
    </Fragment>
  ),
  profit_percent: <Fragment>Divisão percentual entre o Lucro Zenet e o Total Depositado na plataforma.</Fragment>,
  averageRepurchasePeriod: (
    <Fragment>
      Quanto tempo em média os seus apostadores levam para recomprar créditos para jogar na plataforma?
    </Fragment>
  ),
  averageTicket: <Fragment>Valor total de depósitos pelos apostadores dividida pela quantidade de depósitos</Fragment>,
  averageRetentionTime: (
    <Fragment>Período médio de tempo em que os clientes se mantêm ativos, apostando na plataforma.</Fragment>
  ),
  ltv: (
    <Fragment>
      Lifetime Value é uma estimativa da receita média que um cliente irá gerar ao longo de sua vida como cliente
    </Fragment>
  ),
  'churn-rate': (
    <Fragment>É um cálculo percentual entre os clientes inativos do mês atual e ativos do mês passado</Fragment>
  ),
  'churn-average': (
    <Fragment>
      Tendência de chun medido em quantidade de apostadores, baseada nas métricas dos meses anteriores
    </Fragment>
  ),
  'estimated-lost-revenue': (
    <Fragment>
      Receita estimada que seria gerada, caso os os jogadores que se tornaram inativos tivessem apostado o mesmo valor
      que apostaram no mês anterior
    </Fragment>
  ),
  'Zenet Rating': (
    <Fragment>
      É uma média aritmética dos ratings Monetário, de frequência e de Recência. Isso proporciona uma visão geral do
      desempenho do usuário em termos de frequência, valor das apostas e atividade contínua. Quanto mais alto for o
      Zenet Rating, melhor será a sua classificação geral na plataforma.
    </Fragment>
  ),
  'Zenet Rating de Frequência': (
    <Fragment>
      É uma avaliação atribuída ao usuário com base em sua frequência de apostas na plataforma usando ZenetPay como
      método de pagamento. Para calcular esse escore, utilizamos a curva ABC, calculada de forma decrescente. Quanto
      maior a frequência com que você faz apostas, maior será o seu escore. Isso significa que jogadores frequentes
      recebem uma pontuação mais alta.
    </Fragment>
  ),
  'Zenet Rating Monetário': (
    <Fragment>
      É calculado utilizando a curva ABC de forma decrescente. Ele reflete o valor total das suas apostas na plataforma
      utilizando a ZenetPay como método de pagamento. Quanto mais o jogador apostar, maior será o seu escore. Esse
      escore reconhece e fornece pontuações melhores àqueles que investem mais na plataforma.
    </Fragment>
  ),
  'Zenet Rating de Recência': (
    <Fragment>
      É calculado com base na curva ABC de forma crescente. Esse escore indica quantos dias o jogador fica sem fazer
      apostas na plataforma utilizando a ZenetPay como método de pagamento. Em outras palavras, quanto mais tempo o
      usuário passar sem apostar, menor será o seu escore. Este escore incentiva a atividade contínua na plataforma.
    </Fragment>
  ),
  dateNow: <Fragment>A data não pode ser alterada.</Fragment>,
  active: <Fragment>Apostadores que realizaram transações nos últimos 30 dias.</Fragment>,
  vips: (
    <Fragment>
      Apostadores que possuem Zenet Rating maior ou igual a 4, ou seja, possuem alta frequência de depósitos, fazem
      maior investimento monetário e passam poucos dias sem realizar novos depósitos.
    </Fragment>
  ),
  average_active_players: (
    <Fragment>
      Média aritmética da quantidade de apostadores ativos nos últimos 30 dias, ou seja, que realizaram transações na
      plataforma.
    </Fragment>
  ),
  average_entry_transaction_count_per_player: (
    <Fragment>Média aritmética da quantidade de depósitos realizados por apostador nos últimos 30 dias.</Fragment>
  ),
  new_players_ratio: (
    <Fragment>
      Porcentagem de apostadores que realizaram transações na plataforma pela primeira vez nos últimos 30 dias.
    </Fragment>
  ),
} as const;
