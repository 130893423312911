import React from "react";

export const TooltipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{
        margin: "-3px 0px -3px 2px",
      }}
    >
      <path
        d="M10.21 1.33301C10.5636 1.33308 10.9027 1.47361 11.1527 1.72367L14.2767 4.84767C14.5267 5.09766 14.6672 5.43675 14.6673 5.79034V10.209C14.6672 10.5626 14.5267 10.9017 14.2767 11.1517L11.1527 14.2757C10.9027 14.5257 10.5636 14.6663 10.21 14.6663H5.79132C5.43772 14.6663 5.09864 14.5257 4.84865 14.2757L1.72465 11.1517C1.47459 10.9017 1.33406 10.5626 1.33398 10.209V5.79034C1.33406 5.43675 1.47459 5.09766 1.72465 4.84767L4.84865 1.72367C5.09864 1.47361 5.43772 1.33308 5.79132 1.33301H10.21ZM10.21 2.66634H5.79132L2.66732 5.79034V10.209L5.79132 13.333H10.21L13.334 10.209V5.79034L10.21 2.66634ZM8.00065 9.99967C8.17746 9.99967 8.34703 10.0699 8.47205 10.1949C8.59708 10.32 8.66732 10.4895 8.66732 10.6663C8.66732 10.8432 8.59708 11.0127 8.47205 11.1377C8.34703 11.2628 8.17746 11.333 8.00065 11.333C7.82384 11.333 7.65427 11.2628 7.52925 11.1377C7.40422 11.0127 7.33398 10.8432 7.33398 10.6663C7.33398 10.4895 7.40422 10.32 7.52925 10.1949C7.65427 10.0699 7.82384 9.99967 8.00065 9.99967ZM8.00065 3.99967C8.17746 3.99967 8.34703 4.06991 8.47205 4.19494C8.59708 4.31996 8.66732 4.48953 8.66732 4.66634V8.66634C8.66732 8.84315 8.59708 9.01272 8.47205 9.13774C8.34703 9.26277 8.17746 9.33301 8.00065 9.33301C7.82384 9.33301 7.65427 9.26277 7.52925 9.13774C7.40422 9.01272 7.33398 8.84315 7.33398 8.66634V4.66634C7.33398 4.48953 7.40422 4.31996 7.52925 4.19494C7.65427 4.06991 7.82384 3.99967 8.00065 3.99967Z"
        fill="#999999"
      />
    </svg>
  );
};
