import { Navigate, useParams } from 'react-router-dom';
import { lazy } from 'react';
import SidebarComponent from '../components/sidebar';
import { DataTooltip } from '../components/table/components/data-tooltip';

const Login = lazy(() => import('../pages/login'));
const ForgotPassword = lazy(() => import('../pages/forgot-password'));
const CheckEmail = lazy(() => import('../pages/check-email'));
const RecoveryPassword = lazy(() => import('../pages/recovery-password'));
const PasswordChanged = lazy(() => import('../pages/password-changed'));
const Users = lazy(() => import('../pages/users'));
const Reports = lazy(() => import('../pages/reports'));
const UserDetail = lazy(() => import('../pages/user-detail'));
const UsersVip = lazy(() => import('../pages/users-vip'));
const UsersActive = lazy(() => import('../pages/users-active'));
const Groups = lazy(() => import('../pages/groups'));
const GroupName = lazy(() => import('../pages/group-name'));
const DashboardRetention = lazy(() => import('../pages/dashboard-retention'));
const DashboardDemographic = lazy(
  () => import('../pages/dashboard-demographic')
);
const DashboardBehavior = lazy(() => import('../pages/dashboard-behavior'));
const DashboardChurn = lazy(() => import('../pages/dashboard-churn'));
const DashboardBenchmark = lazy(() => import('../pages/dashboard-benchmark'));
const AbandonedTransactions = lazy(
  () => import('../pages/abandoned-transactions')
);

interface Props {
  logged: boolean;
  token?: string;
}

const DynamicNameBreadcrumb = () => {
  const params = useParams();

  return <span>{params.name}</span>;
};

const Router = ({ logged, token }: Props) => [
  {
    path: '/',
    element: logged || token ? <SidebarComponent /> : <Login />,
    children: [
      {
        path: 'players',
        breadcrumb: 'Apostadores',
        children: [
          {
            path: 'general',
            element: <Users />,
            breadcrumb: 'Geral',
          },
          {
            path: 'vips',
            element: <UsersVip />,
            breadcrumb: () => (
              <span>
                VIPs <DataTooltip id="vips" />
              </span>
            ),
          },
          {
            path: 'actives',
            element: <UsersActive />,
            breadcrumb: () => (
              <span>
                Ativos <DataTooltip id="active" />
              </span>
            ),
          },
          {
            path: 'general/:id',
            element: <UserDetail />,
            breadcrumb: 'Detalhes do usuário',
          },
          {
            path: 'abandoned-transactions',
            element: <AbandonedTransactions />,
            breadcrumb: 'Transações Abandonadas',
          },
        ],
      },
      {
        path: 'dashboard',
        breadcrumb: 'Dashboards',
        children: [
          {
            path: 'retention',
            element: <DashboardRetention />,
            breadcrumb: 'Retenção',
          },
          {
            path: 'demographic',
            element: <DashboardDemographic />,
            breadcrumb: 'Demográfico',
          },
          {
            path: 'churn',
            element: <DashboardChurn />,
            breadcrumb: 'Churn',
          },
          {
            path: 'benchmark',
            element: <DashboardBenchmark />,
            breadcrumb: 'Benchmark',
          },
          {
            path: 'behavior',
            element: <DashboardBehavior />,
            breadcrumb: 'Comportamento',
          },
        ],
      },
      {
        path: 'groups',
        element: <Groups />,
        breadcrumb: 'Grupos',
      },
      {
        path: 'groups/:name',
        breadcrumb: DynamicNameBreadcrumb,
        children: [
          {
            path: ':id',
            element: <GroupName />,
            breadcrumb: null,
          },
        ],
      },
      {
        path: 'reports',
        element: <Reports />,
        breadcrumb: 'Exportações',
      },
    ],
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/check-email',
    element: <CheckEmail />,
  },
  {
    path: '/recovery-password',
    element: <RecoveryPassword />,
  },
  {
    path: '/password-changed',
    element: <PasswordChanged />,
  },
  { path: '*', element: <Navigate to="/" /> },
];

export default Router;
