import React, { createContext, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { get_token, reset_password_confirm, get_refresh_token, send_password_reset } from '../../services/auth';
import type { User } from './types';

export interface AuthData {
  logged: boolean;
  token: string;
  setLogged: (logged: boolean) => void;
  setToken: (token: string) => void;
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
  error: string | undefined;
  setError: (error: string | undefined) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  user: User;
  sendPasswordReset: (email: string) => Promise<void>;
  resetPasswordConfirm: (password: string, token: string) => Promise<void>;
}

interface ChildrenProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthData>({} as AuthData);

export const AuthProvider: React.FC<ChildrenProps> = ({ children }) => {
  const navigate = useNavigate();

  const [logged, setLogged] = useState(false);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [user, setUser] = useState<User>({} as User);
  const [refreshToken, setRefreshToken] = useState('');

  const logout = () => {
    setLogged(false);
    setToken('');
    localStorage.removeItem('token_zenet');
    localStorage.removeItem('refresh_token_zenet');
    navigate('/');
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    await get_token(email, password)
      .then((res) => {
        setLoading(false);
        setLogged(true);
        setToken(res.access);
        setRefreshToken(res.refresh);
        setError(undefined);

        localStorage.setItem('token_zenet', res.access);
        localStorage.setItem('refresh_token_zenet', res.refresh);

        navigate('/players/general');
      })
      .catch((err) => {
        console.log('entrou');
        setLoading(false);
        setError(err.message);
      });
  };

  const refreshTokenFunc = async () => {
    const refreshToken = localStorage.getItem('refresh_token_zenet');
    const refreshTokenDecoded = jwt_decode(refreshToken || '');
    console.log(refreshTokenDecoded);

    // await get_refresh_token(refreshToken)
    //   .then((res) => {
    //     setToken(res.access);
    //     localStorage.setItem("token_zenet", res.access);
    //   })
    //   .catch((err) => {
    //     setError(err.detail);
    //   });
  };

  const sendPasswordReset = async (email: string) => {
    setLoading(true);
    await send_password_reset(email)
      .then((res) => {
        setError(undefined);
        setLoading(false);
        window.location.href = '/check-email';
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const resetPasswordConfirm = async (password: string, token: string) => {
    await reset_password_confirm(password, token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        setError(err.detail);
      });
  };

  useEffect(() => {
    const hasToken = token || localStorage.getItem('token_zenet');
    if (hasToken) {
      setLogged(true);
      setToken(hasToken);
      const decodedToken: User = hasToken ? jwt_decode(hasToken, { header: true }) : ({} as User);
      setUser(decodedToken);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        logged,
        token,
        setLogged,
        setToken,
        logout,
        login,
        error,
        setError,
        loading,
        setLoading,
        user,
        sendPasswordReset,
        resetPasswordConfirm,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthData => useContext(AuthContext);
