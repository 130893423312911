import styled from 'styled-components';
import dayjs from 'dayjs';
import { DataTooltip } from '../table/components/data-tooltip';

const DateText = styled.span`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;

const DateNow = () => {
  return (
    <DateText>
      {dayjs().format('DD/MM/YYYY')} <DataTooltip id="dateNow" placement="right-start" />
    </DateText>
  );
};

export default DateNow;
