import {
  token_api,
  refresh_token_api,
  password_reset,
  confirm_password_reset,
} from "..";

export const get_token = async (
  email: string,
  password: string
): Promise<any> => {
  const res = await token_api.post("", {
    email,
    password,
  });
  return res.data;
};

export const get_refresh_token = async (refresh: string): Promise<any> => {
  const res = await refresh_token_api.post("", {
    refresh,
  });
  return res.data;
};

export const send_password_reset = async (email: string): Promise<any> => {
  const res = await password_reset.post("", {
    email,
  });
  return res.data;
};

export const reset_password_confirm = async (
  password: string,
  token: string
): Promise<any> => {
  const res = await confirm_password_reset.post("", {
    password,
    token,
  });
  return res.data;
};
