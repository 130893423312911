import React, { useState } from 'react';

import { BR_STATES } from '../modal-add-user-to-group/const';
import styled from 'styled-components';
import { ArrowDown } from '../table/icons/arrow-down';

const ZenetSelectContainer = styled.div`
  position: relative;
  flex-shrink: 0;

  margin-top: 15px;
  width: 346px;
  height: 60px;
  font-size: 16px;

  border-radius: 4px;
  border: 1px solid #ddd;
  background: #fff;
`;

const ZenetSelectInput = styled.div`
  height: 50px;
  padding-left: 1rem;
  padding-top: 1.2rem;

  border: none;
  border-radius: 4px;

  appearance: none;

  &::placeholder {
    color: #000;
    text-transform: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  cursor: pointer;
`;

const ZenetSelectLabel = styled.label<{ color: boolean }>`
  color: ${(props) => (props.color ? 'black' : '#999')};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

const ZenetSelectOptionContainer = styled.div`
  height: 240px;
  overflow: auto;
  margin-top: 9px;
  width: 360px;
  transform: translateX(-1px);

  position: fixed;
  z-index: 11;
`;

const Option = styled.option<{ i: boolean }>`
  border: 1px solid #ddd;
  border-top: 0px;
  background: #fff;

  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px;

  width: 100%;
  height: 60px;
  flex-shrink: 0;
  text-align: left;

  cursor: pointer;
`;

const ZenetSelectColumnIcon = styled.div`
  height: 24px;
  width: 24px;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 89%;
  transform: translateY(-61%);
  cursor: pointer;
`;

const ZenetIconContainer = styled.div``;

interface Props {
  handleChange: (value: string) => void;
  value?: string;
}

const ZenetSelect = ({ handleChange, value }: Props) => {
  const [visibleOptions, handleVisibleOptions] = useState(false);
  const handleSelect = (state: string) => {
    handleVisibleOptions(!visibleOptions);
    handleChange(state);
  };
  return (
    <ZenetSelectContainer>
      <ZenetSelectInput onClick={() => handleVisibleOptions(!visibleOptions)}>
        <ZenetSelectLabel color={value?.length ? true : false}>
          {BR_STATES[value as keyof typeof BR_STATES] || 'Escolher Estado'}
        </ZenetSelectLabel>
      </ZenetSelectInput>
      {visibleOptions && (
        <ZenetSelectOptionContainer>
          {Object.entries(BR_STATES).map(([initials, state], index) => {
            return (
              <Option i={index % 2 ? true : false} onClick={() => handleSelect(initials)}>
                {state}
              </Option>
            );
          })}
        </ZenetSelectOptionContainer>
      )}
      <ZenetSelectColumnIcon>
        <ZenetIconContainer onClick={() => handleVisibleOptions(!visibleOptions)}>
          <ArrowDown />
        </ZenetIconContainer>
      </ZenetSelectColumnIcon>
    </ZenetSelectContainer>
  );
};

export default ZenetSelect;
