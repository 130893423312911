import React from "react";
import { IconProps } from "./types";

export const Group: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_208_5008)">
        <path
          d="M17.3327 17.3333C18.7472 17.3333 20.1037 17.8952 21.1039 18.8954C22.1041 19.8956 22.666 21.2522 22.666 22.6667V24.6667C22.666 25.1971 22.4553 25.7058 22.0802 26.0809C21.7052 26.456 21.1964 26.6667 20.666 26.6667H4.66602C4.13558 26.6667 3.62687 26.456 3.2518 26.0809C2.87673 25.7058 2.66602 25.1971 2.66602 24.6667V22.6667C2.66602 21.2522 3.22792 19.8956 4.22811 18.8954C5.22831 17.8952 6.58486 17.3333 7.99935 17.3333H17.3327ZM25.3327 17.3333C26.3935 17.3333 27.411 17.7548 28.1611 18.5049C28.9113 19.2551 29.3327 20.2725 29.3327 21.3333V23.3333C29.3327 23.8638 29.122 24.3725 28.7469 24.7475C28.3718 25.1226 27.8631 25.3333 27.3327 25.3333H25.3327V22.6667C25.3335 21.6316 25.0929 20.6105 24.63 19.6847C24.1671 18.7588 23.4946 17.9537 22.666 17.3333H25.3327ZM12.666 4C14.2573 4 15.7834 4.63214 16.9087 5.75736C18.0339 6.88258 18.666 8.4087 18.666 10C18.666 11.5913 18.0339 13.1174 16.9087 14.2426C15.7834 15.3679 14.2573 16 12.666 16C11.0747 16 9.54859 15.3679 8.42338 14.2426C7.29816 13.1174 6.66602 11.5913 6.66602 10C6.66602 8.4087 7.29816 6.88258 8.42338 5.75736C9.54859 4.63214 11.0747 4 12.666 4ZM23.9993 8C25.0602 8 26.0776 8.42143 26.8278 9.17157C27.5779 9.92172 27.9993 10.9391 27.9993 12C27.9993 13.0609 27.5779 14.0783 26.8278 14.8284C26.0776 15.5786 25.0602 16 23.9993 16C22.9385 16 21.9211 15.5786 21.1709 14.8284C20.4208 14.0783 19.9993 13.0609 19.9993 12C19.9993 10.9391 20.4208 9.92172 21.1709 9.17157C21.9211 8.42143 22.9385 8 23.9993 8Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_208_5008">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
