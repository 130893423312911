import styled from "styled-components";
import React from "react";
import { ImageContainer } from "../../login/image-container";
import logo from "../../../assets/images/logo-minimal.png";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const StyledSidebarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  children,
  ...rest
}) => {
  return (
    <StyledSidebarHeader {...rest} data-testid="sidebar-header">
      <LogoContainer>
        <ImageContainer>
          <img src={logo} alt="logo" />
        </ImageContainer>
      </LogoContainer>
    </StyledSidebarHeader>
  );
};
