import { base_api } from "..";

export const get_groups = async (
  limit: number,
  offset: number,
  query?: string | number,
  isNumber?: boolean
): Promise<any> => {
  let url: string;
  if (query && !isNumber) {
    url = `/player-group/player-groups/?limit=${limit}&offset=${offset}&name=${query}`;
  } else if (query && isNumber) {
    url = `/player-group/player-groups/?limit=${limit}&offset=${offset}&code_starts_with=${query}`;
  } else {
    url = `/player-group/player-groups/?limit=${limit}&offset=${offset}`;
  }
  const res = await base_api.get(url);
  return res.data;
};

export const get_groups_users = async (
  limit: number,
  offset: number,
  id: string | number,
  cpf_starts_with?: string | number
): Promise<any> => {
  let url: string;
  if (cpf_starts_with) {
    url = `/player/player-group-players/${id}/?limit=${limit}&offset=${offset}&cpf_starts_with=${cpf_starts_with}`;
  } else {
    url = `/player/player-group-players/${id}/?limit=${limit}&offset=${offset}`;
  }
  const res = await base_api.get(url);
  return res.data;
};

export const add_single_user_to_group = async (
  userId: string[],
  groupId: string
) => {
  const res = await base_api.post(`/player-group/player-groups/players/`, {
    player_ids: userId,
    player_group_id: groupId,
  });
  return res.data;
};
