import { CSSProperties } from "react";
import styled from "styled-components";

type TColor = "primary" | "secondary";

export const ButtonDefault = styled.button<{ color: TColor }>`
  border-radius: 4px;
  border: ${(props) =>
    props.color === "primary" ? "1px solid #4765DD" : "1px solid #4765DD;"};
  background: ${(props) =>
    props.color === "primary" ? "#4765dd;" : "#ffffff"};
  margin-top: 40px;

  color: ${(props) => (props.color === "primary" ? "#fff" : "#4765dd;")};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & > svg {
    &:hover {
      opacity: 0.8;
    }
  }

  transition: all 0.2s ease-in-out;
`;

interface Props {
  label: string;
  onClick?: () => void;
  color?: "primary" | "secondary";
  style?: CSSProperties;
  disabled?: boolean;
}

export const Button = ({
  label,
  onClick,
  color = "primary",
  ...props
}: Props) => {
  return (
    <ButtonDefault color={color} onClick={onClick} {...props}>
      {label}
    </ButtonDefault>
  );
};
