import styled from "styled-components";

export const BreadCrumbText = styled.h1`
  color: rgba(0, 0, 0, 0.5);
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  display: inline;
`;
