import { base_api } from '..';

export type Gender = 'ALL' | 'M' | 'F' | 'U';

export interface Filter {
  gender?: Gender;
  age_min: number;
  age_max: number;
  zenet_rating_min: number;
  zenet_rating_max: number;
  average_entry_transaction_min: number;
  average_entry_transaction_max: number;
  state: string;
  country: string;
}

export interface CreateGroupSchema {
  name: string;
  filter: Filter;
}

export const post_groups = async (data: CreateGroupSchema): Promise<any> => {
  const res = await base_api.post(`/player-group/player-groups/`, data);
  return res.data;
};
