import styled from "styled-components";

export const InputZenet = styled.input<{}>`
  width: 346px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 17px;

  border-radius: 4px;
  border: 1px solid #ddd;
  background: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &::placeholder {
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-transform: none;
  }
`;
