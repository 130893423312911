import styled, { keyframes } from 'styled-components';

import background from '../../assets/images/background.png';

export const Container = styled.div`
  position: fixed;
  inset: 0;

  background-image: url(${background});
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const animationLogo = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
`;
export const Logo = styled.img`
  height: 72px;
  width: auto;

  animation: ${animationLogo} 1s ease-in-out infinite alternate;
`;
