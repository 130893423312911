import { createGlobalStyle } from 'styled-components';
import background from '../assets/images/background.png';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
  }
  html, body, #root {
    min-height: 100vh;
    font-family: Soleil, Arial, Helvetica, sans-serif;
    background-image: url(${background});
    background-size: cover;
    background-position: center;
  }
  button, input, optgroup, select, textarea {
    font-family: inherit;
  }
  body {
    -webkit-font-smoothing: antialiased !important;
    background-color: #E5E5E5;
  }
  ol, ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: none;
  }
  ::placeholder {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  } 

  .skeleton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD !important;

    color: transparent !important;
    border-radius: 4px;

    > * {
      display: none;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255,255,255, 0) 0,
        rgba(255,255,255, 0.2) 20%,
        rgba(255,255,255, 0.5) 60%,
        rgba(255,255,255, 0)
      );
      animation: shimmer 2.5s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export default GlobalStyle;
