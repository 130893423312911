import styled from "styled-components";

export const ActiveItem = styled.h1`
  color: #4765dd;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;
`;
