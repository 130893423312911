import Router from "../../router/index";
// import { useAuth } from "../../contexts/AuthContext";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
import { BreadCrumbText } from "../../components/breadcrumb/breadcrumb-text";
import { Bar } from "../../components/breadcrumb/bar";
import { ActiveItem } from "../../components/breadcrumb/active-item";
import { Fragment } from "react";

const Breadcrumb = () => {
  const routes = Router({ logged: true });

  let breadcrumbs = useBreadcrumbs(routes);
  breadcrumbs = breadcrumbs.filter((breadcrumb) => breadcrumb.key !== "/");

  return (
    <div>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        return (
          <Fragment>
            <NavLink
              key={match.pathname}
              to={match.pathname}
              data-testid={match.pathname}
            >
              {breadcrumbs.length - 1 === index ? (
                <ActiveItem>{breadcrumb}</ActiveItem>
              ) : (
                <BreadCrumbText>{breadcrumb}</BreadCrumbText>
              )}
            </NavLink>
            {breadcrumbs.length - 1 !== index && <Bar>/</Bar>}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
