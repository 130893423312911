import React, { FunctionComponent, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

export const ExportIcon: FunctionComponent<Props> = ({
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_254_1530)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H13V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2ZM13 5V13.828L14.828 12C15.0155 11.8124 15.2699 11.7069 15.5351 11.7068C15.8004 11.7067 16.0549 11.812 16.2425 11.9995C16.4301 12.187 16.5356 12.4414 16.5357 12.7066C16.5358 12.9719 16.4305 13.2264 16.243 13.414L12.883 16.773C12.6486 17.0072 12.3308 17.1387 11.9995 17.1387C11.6682 17.1387 11.3504 17.0072 11.116 16.773L7.757 13.414C7.66416 13.3211 7.59052 13.2108 7.5403 13.0894C7.49008 12.9681 7.46425 12.838 7.4643 12.7066C7.46434 12.5753 7.49026 12.4452 7.54057 12.3239C7.59088 12.2026 7.66459 12.0923 7.7575 11.9995C7.85041 11.9067 7.9607 11.833 8.08207 11.7828C8.20343 11.7326 8.3335 11.7068 8.46485 11.7068C8.5962 11.7068 8.72626 11.7328 8.84759 11.7831C8.96892 11.8334 9.07916 11.9071 9.172 12L11 13.828V5H13Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_254_1530">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
