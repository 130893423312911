import React, { InputHTMLAttributes } from 'react';

import NumberFormat from 'react-number-format';

interface InputProps extends Pick<InputHTMLAttributes<HTMLInputElement>, 'className'> {
  onChangeInput(value: number): void;
  currentValue?: string | number;
  maxValue?: number;
  minValue?: number;
}

const MoneyInput: React.FC<InputProps> = ({
  currentValue,
  onChangeInput,
  className,
  maxValue = Infinity,
  minValue = -Infinity,
}: InputProps) => {
  const formatCurrencyByEnd = (value: string): string => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(parseFloat(value) / 100);

    return `${amount}`;
  };

  return (
    <NumberFormat
      format={formatCurrencyByEnd}
      allowNegative={false}
      fixedDecimalScale
      decimalScale={2}
      inputMode="numeric"
      displayType="input"
      value={!currentValue || !Number(currentValue) ? '' : Number(currentValue) * 100}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;

        return formattedValue === '' || (floatValue ?? 0) / 100 <= maxValue;
      }}
      onValueChange={(values) => {
        const { floatValue } = values;

        onChangeInput(
          (floatValue ?? 0) / 100 < minValue ? minValue : Number((parseFloat(values.value) / 100).toFixed(2))
        );
      }}
      className={className}
    />
  );
};

export default MoneyInput;
